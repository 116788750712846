// assets
import { IconHomeHeart,IconGitCommit,IconMailbox, IconTargetArrow } from '@tabler/icons';

// constant
const icons = { IconHomeHeart };

// ==============================|| home MENU ITEMS ||============================== //

const home = {
    id: 'home',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'home',
            title: '家',
            type: 'item',
            url: '/home',
            icon: icons.IconHomeHeart,
            breadcrumbs: false
        },
        {
            id: 'threads-main',
            title: '碎碎念',
            type: 'item',
            url: '/threads',
            icon: IconMailbox,
            breadcrumbs: false
        },
        {
            id: 'recruit-info-main',
            title: '羊羊秋招',
            type: 'item',
            url: '/recruit',
            icon: IconTargetArrow,
            breadcrumbs: false
        },
        {
            id: 'versions-history',
            title: '版本',
            type: 'item',
            url: '/versions-history',
            icon: IconGitCommit,
            breadcrumbs: false
        }
    ]
};

export default home;
