// Define your Flask API base URL
// export const API_URL_BASE = 'https://sheepyii.love/api';
export function RedirectToLogin() {
    console.log('Linton: not logged in');
    window.location.replace("/login");
}

export function RedirectToAfterLoginPage() {
    console.log('Linton: login success.')
    window.location.replace("/");
}