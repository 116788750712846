import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ThreadsRoutes from './ThreadsRoutes';
import RecruitRoutes from './RecruitRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // return useRoutes([AuthenticationRoutes]);
    return useRoutes([MainRoutes, ThreadsRoutes, RecruitRoutes, AuthenticationRoutes]);
}
