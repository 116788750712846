import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const ThreadsPage = Loadable(lazy(() => import('views/threads/index')));
const CreateThreadsPage = Loadable(lazy(() => import('views/threads/create/index')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const ThreadsRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'threads',
            element: <ThreadsPage />
        },
        {
            path: 'threads/create',
            element: <CreateThreadsPage />
        }
    ]
};

export default ThreadsRoutes;
