import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const RecruitPage = Loadable(lazy(() => import('views/recruit/index')));
const CreateRecruitInfoPage = Loadable(lazy(() => import('views/recruit/create/index')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const RecruitRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'recruit',
            element: <RecruitPage />
        },
        {
            path: 'recruit/create',
            element: <CreateRecruitInfoPage />
        }
    ]
};

export default RecruitRoutes;
