import axios from 'axios';

import {API_URL_BASE} from 'api_url.js'

// Define your Flask API base URL
const API_URL = `${API_URL_BASE}/auth`;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Service for user registration
const register = (username, email, password) => {
    return axiosInstance.post('/register', {
        username,
        email,
        password,
    });
};

// Service for user login
const login = async (username, password, remember) => {
    try {
        const response = await axiosInstance.post('/login', {
            username,
            password,
            remember,
        });

        // Store the token in localStorage (or cookies if necessary)
        localStorage.setItem('token', response.data.access_token);

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Login failed');
    }
};

// Service for logging out
const logout = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('No token found');
    }

    try {
        // Call the backend to perform logout (optional)
        const response = await axiosInstance.post('/logout', {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        // Remove token from localStorage
        localStorage.removeItem('token');

    } catch (error) {
        throw error.response ? error.response.data : new Error('Logout failed');
    }
};

// Service for accessing protected data
const getProtectedData = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('No token found');
    }

    try {
        const response = await axiosInstance.get('/loggedin-test', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Failed to fetch protected data');
    }
};



const isAuthenticated = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        return false; // No token found, user is not authenticated
    }

    try {
        // Make a request to the backend to verify the token
        const response = await axiosInstance.get('/loggedin-test', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return true; // Token is valid
    } catch (error) {
        console.error('Session invalid or expired:', error);
        return false; // Token is invalid or expired
    }
};


export default {
    register,
    login,
    logout,
    getProtectedData,
    isAuthenticated,
};
