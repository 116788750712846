// import dashboard from './dashboard';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';
import home from './home';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [home]
    // items: [dashboard, pages, utilities, other]
};

export default menuItems;
