import axios from 'axios';

import { API_URL_BASE } from 'api_url'

// Define your Flask API base URL
const API_URL = `${API_URL_BASE}/dashboard`;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Service for accessing protected data
const getLoveDays = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('No token found');
    }

    try {
        const response = await axiosInstance.get('/love-days', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Failed to fetch protected data');
    }
};



const getProfileSectionData = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
        throw new Error('No token found');
    }

    try {
        const response = await axiosInstance.get('/profile-section', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : new Error('Failed to fetch protected data');
    }
};


export default {
    getLoveDays,
    getProfileSectionData,
};
